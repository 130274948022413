import React from 'react'
import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'
import {Context, Flags} from './App'
import {UserType, userNone} from './caltypes'
import {BodyLogout, fetch_logout} from './fetchHelpers'
import {is_auth} from './utilrole'
import {Btn, BtnA} from './parts/Btn'
//
// icons ここで使うもの
//
//const FasUserSlash = () => <Fa icon={['fas', 'user-slash']} className="fa-lg" />
const FasTimesCircle = () => <Fa icon={['fas', 'times-circle']} className="fa-lg" />

//
// ●Logout
//
export const Logout = ({user, setUser, flags, setFlags}: {
  user:UserType,
  setUser: React.Dispatch<React.SetStateAction<UserType>>;
  flags: Flags;
  setFlags: React.Dispatch<React.SetStateAction<Flags>>;
}) :JSX.Element => {
  const {socket, urls} = React.useContext(Context)
  const [msg, setMsg] = React.useState('')
  //const [canLogout, setCanLogout] = React.useState(false)

  

  React.useEffect( ()=> {
    if(is_auth(user)){
      //setCanLogout(true)
      setMsg('←ログアウトを押してください')
    }else{
      //setCanLogout(false)
      setMsg('(ログインしていません)')
    }
    return ()=>{}
  }, [user, setMsg])
  //
  // ●ログアウト実行
  //
  const handleLogout = async () =>{
    // 2024-0704 socket.id が undefinedな場合のケアが必要となった
    const body: BodyLogout= {socket_id: socket.id ? socket.id : ''}
    const url= urls.logout // notifyなし版
    const res = await fetch_logout(url, body)
    if(res.success){
      setMsg('ログアウトが成功しました') // OK: You were authenticated, so logout done.
      setUser(userNone)
      //clearで この画面を非表示でいいのかな。
      clear()
    } else {
      //これはどのような？場合か。      
      setMsg(res.msg) // NG: NO logout since you are NOT authenticated.
      //setUser({id:-1, username:''})
    }
  }
  // 
  function clear(){
    setFlags({...flags, fLogout: false})
  }
  //
  // render
  //
  return(<>

    <div>
      {/* <FontAwesomeIcon icon={{prefix:'fal', iconName:'user-slash'}} className="fa-lg" />{' '} */}
      {is_auth(user) ? `${user.username} がログインしています` : '(ログインしていません)'}
    </div>

    <div className="input-group mb-1">

      <BtnA handle={handleLogout}>
        {/* <FasUserSlash /> */}
        ログアウト
      </BtnA>

      <div className="input-group-text form-control">{msg}</div>

      <Btn handle={clear}>
        <FasTimesCircle />
      </Btn>

    </div>

  </>)
}
