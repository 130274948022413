//
// utilrole
//
import {format} from 'date-fns'
import {UserType} from './caltypes'


// これは大丈夫かいいかげんだけど。//2025 改修
//export const is_auth = (user: UserType) : boolean => (0 < user.username.length)
export const is_auth = (user: UserType) : boolean =>{
  if(user && user.username && 0<user.username.length){ return true}
  else {return false}
}
//
// ●is_admin
// server で (users.ts) 予約名として追加削除などが効かない adminを定義している。
// ここではそれら admin(現在は admin* root* 名) を規定して AdminToolなどを選択できないように使う。
// (id番号はいまのところserverでも使っていない)ので 変更した
export function is_admin(username: string) : boolean {
  
  if(!username) {
    const stamp= format(new Date(), 'HH:mm:ss')
    console.log(`@@@is_admin but/not yet? username undefined (${stamp})`)
    return false
  }
  if(username==='') {
    console.log(`@@@is_admin but username empty`)
    return false
  }
  const reserved_names = ['admin', 'root']
  const hit = (r: string, who: string) :boolean => {
    const pattern= new RegExp(`^${r}`, 'i')
    return who.match(pattern) ? true: false // nullなら falseで。
  }
  const found= reserved_names.find(name => hit(name, username))
  return found ? true: false // undefinedなら falseで。
}


//
//
// splitについて
/*
  区切り文字が 空白ひとつ の場合、
  文字列が 空文字なら split('', sp1) は [''] 空文字の配列、要素数１になる。
  これに 追加 すると (push) ['', 'notified'] となる。当然。
  だから、
  joinでもどすと、' notified' というように先頭の空白１文字が入る。
  これを splitすると当然 length2の配列になるので lengthは考慮せず、配列要素を回して判定すればいい。
  password ファイル上では ’’ で初めて良いし、属性が一つでも入れば 空白先頭になるが...問題はない。
*/
//
// ●role uty
//  has_role
//  add_role
//  del_role
//  attr_role
//  set_notified_exclusive
//

//
// 〇chk_role  現在のrole文字列を配列にして指定roleが存在するか。
//
export function has_role(u: UserType, role: string){
  const rr = u.role.split(' ')
  const found = rr.find( r => r === role)
  return found===undefined ? false : true
}
// そのrole が u.roleに存在しなければ追加する
export function add_role(u: UserType, role: string){
  if( has_role(u, role) ){
    return false
  }
  // 追加する  
  const rr = u.role.split(' ')
  rr.push(role)
  u.role= rr.join(' ')
  return true

}
// 'notified admin' --> 'admin'など
export function del_role(u: UserType, role: string){
  if( !has_role(u, role) ){
    return false
  }
  // 削除する
  //const rr = u.role.split(' ')
  const rr2 = u.role.split(' ').filter( r => r !== role )
  u.role= rr2.join(' ')
  return true
}
//
// ●attr_role ( user.role 属性がなければつけておく)
// (今は不要かな)
export function attr_role(users: UserType[]){
  for(const user of users){
    if( 'role' in user === false ){
      //user.role = '' // 2025 skip it
    }
  }
}
//
// ●set-roleの排他処理  (複数の usernameに対して role項目を set する、他をリセットする)
//
// 指定ユーザーにのみ 'notified' 属性を追加し、その他からは、notified 属性を削除する。
//
export function set_notified_exclusive(uu: UserType[], targets: string[]){
  //まず全部から削除しておいて
  for( const u of uu) del_role(u, 'notified')
  //対象ユーザーに属性をつける
  let count= 0
  for(const t of targets){ //対象となるユーザー名
    const u = uu.find( u => u.username === t)
    if( u ){ // not undefinedのとき
      add_role(u, 'notified')
      count++
    }else{
      console.log(`${t} ユーザーは存在しません。`)
    }
  }
  if(count){
    return ({success: true, msg: `OK: ${count} users role notified`})
  }else{
    return ({success: false, msg: `NG: ${count} users role notified`})
  }
}
